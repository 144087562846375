import Landing from "../../components/landingscreen/Landing";
import Header from "../../components/Header/Header";
import "./Home.css";
import CTA from "../../components/cta/cta"
import Footer from "../../components/footer/footer";
import Cards from "../../data/landingdata.json"
import SlideShow from "../../components/carousel/carousel";
import { useRef } from "react";
import CookieConsent from "../../components/alert/Cookie";

export default function Home() {
  const readMore = useRef(null)
  const executeScroll = () => readMore.current.scrollIntoView();    
  return (
    <>
      <div className="page">
      <div style={{minHeight:"20vh", position:"fixed", top:"0", minWidth:"100%"}}  >
          <Header/>
      </div>
      <div className="vcenter" style={{minHeight:"100vh", justifyContent:"center"}}>
      <Landing />
      <CTA primaryAction={null} secondaryAction={executeScroll}/>
      </div>
      </div>
      <div className="page" ref={readMore} style={{paddingTop:"10vh"}} >
      <SlideShow items={Cards.cards}/>
      </div>
      <Footer/>
      <CookieConsent />

    </>
  );
}


