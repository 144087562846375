import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from "react";
import "./cta.css";
import { Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
export default function CTA({ primaryAction, secondaryAction, hidden }) {
  const [open, setOpen] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return (
    <>
      <Dialog
        sx={{overflowY:'hidden'}}
        maxWidth="1290px"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpen(false);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Register for the waitlist!</DialogTitle>
        <div classes="iframeholder">
        
          <iframe
            title="registration "
            src="https://docs.google.com/forms/d/e/1FAIpQLSe2J7bWI832JI4g_frWT_N4nm3rK8tV6PXJGxOPi8FEGKT2ww/viewform?embedded=true"
            width="1024"
            height={(0.7*windowSize.innerHeight)+"px"}
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </div>
        <DialogActions>
          <Button onClick={()=>setOpen(false)}>Done</Button>
        </DialogActions>
      </Dialog>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <button className="button primary" onClick={() => setOpen(true)}>
          Join the waitlist
        </button>
        {(!hidden) && <button onClick={secondaryAction} className="button secondary">
          {" "}
          Read more
        </button>}
      </div>
    </>
  );
}
