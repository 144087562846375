import { Paper } from "@mui/material";
import Footer from "../../components/footer/footer";
import Header from "../../components/Header/Header";
import data from "../../data/config.json"

export default function Privacy() {
  return (
    <>
      <div
        style={{
          minHeight: "20vh",
          position: "fixed",
          top: "0",
          minWidth: "100%",
        }}
      >
        <Header />
      </div>
      <div style={{ display:'flex', justifyContent:'center', minHeight: "80vh", margin: "20vh 0 0 0" }}>
        <Paper elevation={3} sx={{ maxWidth:'800px', padding:'0 10% 10% 10%'}}>
          <h1> Privacy Policy</h1>
          At {data.title}, we are committed to protecting your privacy. We use
          the information we collect about you to provide you with a
          personalized experience on our website and to keep you informed about
          the products and services we offer. We will not sell, rent, or give
          your personal information to any third party without your consent,
          except as required by law.
          <h2> information Collected</h2>
          <p>
              We may collect the following information from you when you use our
              website: Your name and contact information, including your email
              address and phone number Your IP address and browsing history on our
              website Any personal information you choose to provide in your profile
              or in any communications with us We may also collect information about
              your use of our website, including the pages you visit, the links you
              click, and the searches you make.
          </p>
          <h3>Google Login</h3>
          We offer the option to log in to our website using your Google
          account. If you choose to log in using Google, you will be asked to
          grant permission for us to access certain information from your Google
          account. This may include your name, email address, and any other
          information that you have made public in your Google profile. We will
          only use the information we access from your Google account to
          personalize your experience on our website and to keep you informed
          about the products and services we offer.
          <h2>Security</h2>
          We take the security of your personal information seriously. We have
          implemented appropriate measures to protect your personal information
          from unauthorized access, disclosure, alteration, or destruction.
          <h2>Changes to this Policy</h2>
          We may update this privacy policy from time to time to reflect changes
          to our practices or for other operational, legal, or regulatory
          reasons. We encourage you to review the privacy policy whenever you
          access our website to stay informed about our information practices
          and the choices available to you.
          <h2>Contact Us</h2>
          If you have any questions about this privacy policy or the information
          we collect, please contact us at <pre>xod.fin@gmail.com</pre>.
          <h2>Legal Disclaimer</h2>
          The information contained on {data.title} is for general information
          purposes only. The information is provided by {data.title} and while
          we strive to keep the information up to date and correct, we make no
          representations or warranties of any kind, express or implied, about
          the completeness, accuracy, reliability, suitability or availability
          with respect to the website or the information, products, services, or
          related graphics contained on the website for any purpose. Any
          reliance you place on such information is therefore strictly at your
          own risk. In no event will we be liable for any loss or damage
          including without limitation, indirect or consequential loss or
          damage, or any loss or damage whatsoever arising from loss of data or
          profits arising out of, or in connection with, the use of this
          website. Through this website you are able to link to other websites
          which are not under the control of {data.title}. We have no control
          over the nature, content, and availability of those sites. The
          inclusion of any links does not necessarily imply a recommendation or
          endorse the views expressed within them. Every effort is made to keep
          the website up and running smoothly. However, {data.title} takes no
          responsibility for, and will not be liable for, the website being
          temporarily unavailable due to technical issues beyond our control.
        </Paper>
      </div>
      <Footer/>
    </>
  );
}
