import React from "react";
import { Paper } from "@mui/material";
import Slide from "../slide/slide";
import CTA from "../cta/cta";

function Item({ data, left }) {
  return (
    <Paper style={{borderBottom:"1px solid #BBB", padding:"30px"}}>
      <Slide data={data} left={left} />
    </Paper>
  );
}

export default function SlideShow({ items }) {
  return (<>
        <div style={{display:"flex",backgroundColor:"white", width:"100%", justifyContent:"center" }}>
            <div style={{display:"flex",flexDirection:"column", maxWidth:"1024px"}}>
      {items.map((item, i) => (
        <Item key={"item-"+i} data={item} left={i % 2 === 0} />
      ))}
      </div>
      
      </div>
      <div style={{margin:'25px'}}></div>
      <CTA hidden={true}/></>
  );
}
