import Header from "../../components/Header/Header";

export default function NoPage(){
    return (
        <>
          <div style={{minHeight:"20vh", position:"fixed", top:"0", minWidth:"100%"}}  >
              <Header/>
          </div>
          <div>
            <p> This page is not found!</p>
          </div>
        </>
        );
}