import React from "react";
import "./section.css";
export default function Section({ children }) {
  return (

      <div className="section-container">
        {children &&
          React.Children.map(children, (child) => (
            <div className="section-child">{child}</div>
          ))}
      </div>
  );
}
