import Section from "../section/section";
import "./footer.css";
import contact from "../../imgs/contact.png"

export default function Footer() {
  return <Section>
    <div style={{opacity:0.6, display: "flex",justifyContent:"center", paddingRight:"0px"}}>
        <img src={contact} width={200} alt="robot"/>
    </div>
    <div style={{ display:'flex', flexDirection:'column', minWidth:'100%',minHeight:"200px", textAlign:'left', justifyContent:"flex-end", paddingBottom:"20px"}}>
      <div>
        Copyright © {new Date().getFullYear()} {window.location.hostname}
      </div>
      <div>
        <a href="/privacy">Privacy and Legal</a>
      </div>
      <div>
        <a href="/about">Know more about us</a>
      </div>
    </div>
  </Section>;
}
