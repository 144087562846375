import "./Header.css";
import logo from "../../imgs/logo.png";
import data from "../../data/config.json"

export default function Header() {
  return (
    <header
      className="pageHeader"
    >
      <div className="pageTitle">
      <img src={logo} alt="logo" height="75px" />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1 className="title">
          <span style={{ marginLeft: "10px" }}>{data.title} </span>
        </h1>
        <h4 className="subtitle"> {data.subtitle
        }</h4>
      </div>
      </div>
    </header>
  );
}
