import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import config from "../../data/config.json"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CookieConsent() {
  const [open, setOpen] = React.useState(false);
  React.useEffect(()=>{
    let cc = localStorage.getItem("cookie_consent")
    if(!cc) handleClickOpen();
  },[] )

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    localStorage.setItem("cookie_consent", true)
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>We value your privacy!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {config.title} uses cookies to enhance your browsing experience, serve personalized content and analyse our traffic.
             By clicking agree, you consent to the use of cookies. We do NOT track indiviual users for personalized ads.
             You may read more about our <a href="/privacy">privacy policy here</a> 

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>I Agree</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}