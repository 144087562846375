import Section from "../section/section"
import "./slide.css"
import config from "../../data/config.json"

export default function Slide({data, left})
{
    const img = <div className="imgcontainer">
        <img className="slideimg" src={require("../../imgs/"+data.image)} height="300px" alt = {data.img}/>
    </div> 
    const text = <>
    <h2>{data.heading}</h2>
    <p>
        {data.text.replace(/{title}/g, config.title)}
    </p> </>

    return left? <Section>{img}{text}</Section> :<Section>{text}{img}</Section> 


}