import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import NoPage from "./pages/404/NoPage";
import Privacy from "./pages/privacy/Privacy";
import "./App.css";

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route exact path='/' element={< Home />}></Route>  
        <Route exact path='/privacy' element={< Privacy />}></Route>  
        <Route exact path='*' element={< NoPage />}></Route>  
        </Routes>
      </BrowserRouter>
    </div>
  );
}
