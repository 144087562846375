import "./Landing.css";
import background from "../../imgs/landing-backgound.png";
import welcome from "../../data/welcome.json";
import { useCallback, useEffect, useState } from "react";
import data from "../../data/config.json"; 


export default function Landing() {
  const [greeting, setGreeting] = useState("Welcome");
  const shuffle = useCallback(() => {
    const index = Math.floor(Math.random() * welcome.translations.length);
    setGreeting(welcome.translations[index]);
  }, []);

  useEffect(() => {
    const intervalID = setInterval(shuffle, 5000);
    return () => clearInterval(intervalID);
  }, [shuffle]);

  return (
    <>
    <section className="landing-container">
      <div className="float-container">
        <div className="float-child" id="landing-img" style={{alignSelf:"flex-end"}}>
          <img src={background} width="80%" alt="ominous-bot" />
        </div>

        <div className="float-child" style={{ textAlign: "left" }}>
          <h1>{greeting}</h1>
          <p> Are you tired of lugging around a bulky console or
          PC to play your favorite games? Introducing {data.title}, the ultimate
          solution for mobile gaming. With {data.title}, you can access a vast
          library of top-rated games on your phone or tablet without the need
          for expensive hardware. Simply download the app and start playing
          within seconds. But it's not just about convenience - 
          {data.title} offers stunning graphics and lightning-fast performance
          that rivals even the most high-end gaming systems. Plus, with regular
          updates and new games added all the time, there's always something new
          to discover. 
          </p> 
          
          <p>
            We are currently in an alpha stage where we are requesting users to join a waitlist. We shall reach out to you over 
            email as provided.
          </p>
   
        </div>
      </div>
    </section>

    </>
  );
}
